module services {
    export module manifest {
        export class manifestTypeService implements interfaces.manifest.IManifestTypeService{
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdown(ownerEntityId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ManifestType/GetDropdown", {
                    ownerEntityId: ownerEntityId
                });
            }

            getManifestTypeDefaults(manifestTypeId: number): ng.resource.IResourceClass<interfaces.manifest.IManifestDefaults> {
                return this.$resource<interfaces.manifest.IManifestDefaults>(this.ENV.DSP_URL + "ManifestType/GetManifestTypeDefaults", {
                    manifestTypeId: manifestTypeId
                });
            }

            getDefaultManifestType(ownerEntityId?: number): ng.resource.IResourceClass<interfaces.manifest.IManifestDefaults> {
                return this.$resource<interfaces.manifest.IManifestDefaults>(this.ENV.DSP_URL + "ManifestType/GetDefaultManifestType", {
                    ownerEntityId: ownerEntityId
                });
            }

        }
    }
    angular.module("app").service("manifestTypeService", services.manifest.manifestTypeService);
}